define([], function() {

  var viewport = function() {

    var self = this;
    var defaultOffset = 0;

    self.isElementVisible = function(element, offset) {
      // select the element from the DOM if a selector is passed
      offset = offset || defaultOffset;

      if (typeof (element) === 'string') {
        element = document.querySelector(element);
      }
      if (element) {
        let rect = null;
        try {
          rect = element.getBoundingClientRect();
        } catch (e) {
          rect = {top: element.offsetTop, left: element.offsetLeft};
        }
        return !(self.isAbove(rect.bottom + offset) || self.isBelow(rect.top - offset));
      }
      return false;
    };

    self.getFromViewportTop = function(domElement) {
      return domElement.getBoundingClientRect().top;
    };

    self.getFromViewportLeft = function(domElement) {
      return domElement.getBoundingClientRect().left;
    };

    self.isAbove = function(value) {
      return value < 0;
    };

    self.isBelow = function(value) {
      var getMaxClient = self.getMaxClientOrInnerHeight();
      return value - getMaxClient >= 0;
    };

    self.getMaxClientOrInnerHeight = function() {
      return Math.max(document.documentElement.clientHeight, window.innerHeight);
    };

    self.getMaxClientOrInnerWidth = function() {
      return Math.max(document.documentElement.clientWidth, window.innerWidth);
    };

    self.isElementFullyVisible = function(element) {
      var rect = element.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    };
  };

  return new viewport();
});
